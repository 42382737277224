import { gql } from '@apollo/client'
import {
	WORKER_FRAGMENT,
	WORK_EXPERIENCE_FRAGMENT,
	WORKER_CERTIFICATION_FRAGMENT,
	SALESFORCE_INTEGRATION_FRAGMENT,
	IMAGE_FILE_FRAGMENT,
	DIRECT_UPLOAD_FRAGMENT,
	WORKED_TIMESHEET_FRAGMENT,
} from '../apollo.fragment'

export const SIGNIN_WORKER_MUTATION = gql`
	mutation signInWorker($email: String!, $password: String!, $recaptchaToken: String, $socialAuthenticationId: String) {
		signInWorker(recaptchaToken: $recaptchaToken, email: $email, password: $password, socialAuthenticationId: $socialAuthenticationId) {
			authToken
			notificationChannel
			worker {
				...workerFragment
				recruiter {
					id
					name
					phone
					email
					title
				}
			}
		}
	}

	${WORKER_FRAGMENT}
`

export const VERIFY_OAUTH_LOGIN = gql`
	mutation verifyOauthLogin(
		$providerName: SocialProviderEnum!
		$clientId: String!
		$clientToken: String
		$email: String
		$code: String
		$redirectUri: String
		$recaptchaToken: String
	) {
		verifySocialAuthentication(
			providerName: $providerName
			clientId: $clientId
			clientToken: $clientToken
			email: $email
			code: $code
			redirectUri: $redirectUri
			recaptchaToken: $recaptchaToken
		) {
			authToken
			emailHasAccount
			notificationChannel
			userSocialAuthentication {
				email
				providerName
				id
				providerUuid
				userId
			}
			worker {
				company {
					id
					logoUrl
					name
					country
					city
					address
				}
				email
				firstName
				id
				lastName
				phone
				phoneVerifiedAt
				workAuthorized
			}
		}
	}
`

export const CREATE_WORKER_MUTATION = gql`
	mutation createWorker(
		$recaptchaToken: String
		$firstName: String!
		$lastName: String!
		$email: String!
		$companyId: String!
		$phone: String!
		$discipline: String!
		$specialty: String
		$experience: String!
		$referralMethod: String
		$referredByName: String
		$referenceRelationships: String
		$signedBlobId: String
		$socialAuthenticationId: String
	) {
		createIntegrationWorker(
			socialAuthenticationId: $socialAuthenticationId
			recaptchaToken: $recaptchaToken
			firstName: $firstName
			lastName: $lastName
			email: $email
			companyId: $companyId
			phone: $phone
			signedBlobId: $signedBlobId
			referralMethod: $referralMethod
			referredByName: $referredByName
			referenceRelationships: $referenceRelationships
			# signedBlobIds: $signedBlobIds
			workExperienceOverviewAttributes: { discipline: $discipline, primarySpecialty: $specialty, yearsOfExperience: $experience }
			source: opus_web_portal
		) {
			authToken
			worker {
				...workerFragment
				company {
					salesforceIntegration {
						...salesforceIntegrationFragment
					}
				}
			}
		}
	}

	${WORKER_FRAGMENT}
	${SALESFORCE_INTEGRATION_FRAGMENT}
`

export const UPDATE_WORKER_MUTATION = gql`
	mutation updateWorker($id: String!, $referral: String!) {
		updateIntegrationWorker(id: $id, referralMethod: $referral) {
			id
		}
	}
`

export const UPDATE_WORKER_LOCATION_MUTATION = gql`
	mutation createWorkingPreferredLocations($id: String!, $location1: String, $location2: String, $location3: String) {
		createWorkingPreferredLocations(
			id: $id
			workingPreferredLocationsAttributes: [{ preferredWorkingState: $location1 }, { preferredWorkingState: $location2 }, { preferredWorkingState: $location3 }]
		) {
			success
		}
	}
`

export const SEND_PASSWORD_RESET_WORKER_MUTATION = gql`
	mutation sendPasswordResetWorker($email: String!) {
		sendPasswordResetWorker(email: $email) {
			success
		}
	}
`

export const SEND_PASSWORD_RESET_RECRUITER_MUTATION = gql`
	mutation sendPasswordResetRecruiter($email: String!, $recaptchaToken: String) {
		sendPasswordResetRecruiter(email: $email, recaptchaToken: $recaptchaToken) {
			success
		}
	}
`

export const RESET_PASSWORD_WORKER_MUTATION = gql`
	mutation resetPasswordWorker($token: String!, $password: String!, $passwordConfirmation: String!, $recaptchaToken: String) {
		resetPasswordForUser(recaptchaToken: $recaptchaToken, token: $token, password: $password, passwordConfirmation: $passwordConfirmation) {
			success
		}
	}
`

export const RESET_PASSWORD_RECRUITER_MUTATION = gql`
	mutation resetPasswordForWorker($email: String!, $token: String!, $password: String!, $passwordConfirmation: String!, $recaptchaToken: String) {
		resetPasswordForWorker(email: $email, recaptchaToken: $recaptchaToken, token: $token, password: $password, passwordConfirmation: $passwordConfirmation) {
			success
		}
	}
`

export const UPDATE_WORK_EXPERIENCE_MUTATION = gql`
	mutation updateWorkExperience($id: String!, $experiences: [WorkExperienceArgument!]) {
		updateIntegrationWorker(id: $id, workExperiencesAttributes: $experiences) {
			workExperiences {
				...workExperienceFragment
			}
		}
	}
	${WORK_EXPERIENCE_FRAGMENT}
`

export const UPDATE_WORKER_LICENSE_MUTATION = gql`
	mutation updateWorkerLicenses($id: String!, $licenses: [WorkerCertificationArgument!]) {
		updateIntegrationWorker(id: $id, workerCertificationsAttributes: $licenses) {
			id
			workerCertifications {
				...workerCertificationFragment
			}
		}
	}

	${WORKER_CERTIFICATION_FRAGMENT}
`

export const CREATE_DIRECT_UPLOAD_MUTATION = gql`
	mutation createDirectUpload($filename: String!, $byteSize: Int!, $checksum: String!, $contentType: String!) {
		createDirectUpload(filename: $filename, byteSize: $byteSize, checksum: $checksum, contentType: $contentType) {
			...directUploadFragment
		}
	}
	${DIRECT_UPLOAD_FRAGMENT}
`

export const CREATE_WORKED_TIMESHEET_MUTATION = gql`
	mutation createWorkedTimesheet($workerId: String!, $signedBlobId: [String!]!, $endDate: String!) {
		createWorkedTimesheet(workerId: $workerId, signedBlobId: $signedBlobId, endDate: $endDate) {
			...workedTimesheetFragment
			timesheetImages {
				...imageFileFragment
			}
		}
	}
	${WORKED_TIMESHEET_FRAGMENT}
	${IMAGE_FILE_FRAGMENT}
`

export const DESTROY_FILE_MUTATION = gql`
	mutation destroyFile($id: String!) {
		destroyFile(id: $id) {
			id
			success
		}
	}
`

export const UPDATE_WORKED_TIMESHEET_MUTATION = gql`
	mutation updateWorkedTimesheet($id: String!, $signedBlobId: [String!]!) {
		updateWorkedTimesheet(id: $id, signedBlobId: $signedBlobId) {
			...workedTimesheetFragment
			timesheetImages {
				...imageFileFragment
			}
		}
	}
	${WORKED_TIMESHEET_FRAGMENT}
	${IMAGE_FILE_FRAGMENT}
`
export const UPDATE_WORKER_RESUME_MUTATION = gql`
	mutation RecruiterCreateOrUpdateWorkerResumes($workerId: String!, $resumes: [MaestroDocumentInput!]) {
		updateWorker(workerId: $workerId, resumes: $resumes) {
			id
		}
	}
`

export const SEND_OTP_CODE_MUTATION = gql`
	mutation sendOtpCodeMutation($phoneNumber: String!) {
		sendOtpCode(phoneNumber: $phoneNumber) {
			success
		}
	}
`

export const VERIFY_OTP_CODE_MUTATION = gql`
	mutation verifyOtpCodeMutation($otpCode: String!, $phoneNumber: String!) {
		verifyOtpCode(otpCode: $otpCode, phoneNumber: $phoneNumber) {
			success
		}
	}
`

export const UPDATE_WORKER_EMERGENCY_CONTACT = gql`
	mutation updateWorkerEmergencyContact($id: String, $firstName: String, $lastName: String, $relationship: String, $phone: String, $workerId: String!) {
		updateWorkerEmergencyContact(id: $id, firstName: $firstName, lastName: $lastName, relationship: $relationship, phone: $phone, workerId: $workerId) {
			firstName
			lastName
			id
			phone
			relationship
		}
	}
`

export const CREATE_WORKER_EMERGENCY_CONTACT = gql`
	mutation createWorkerEmergencyContact($id: String, $firstName: String, $lastName: String, $relationship: String, $phone: String, $workerId: String!) {
		createWorkerEmergencyContact(id: $id, firstName: $firstName, lastName: $lastName, relationship: $relationship, phone: $phone, workerId: $workerId) {
			firstName
			lastName
			id
			phone
			relationship
		}
	}
`

export const UPDATE_WORKER_INFO_MUTATION = gql`
	mutation updateWorkerInfo(
		$workerId: String!
		$availableStartDate: ISO8601Date
		$preferredShifts: [String!]
		$addresses: [MaestroAddressInput!]
		$workerAvailabilities: [MaestroWorkerAvailabilityInput!]
		$licenseStates: String
		$workerDisciplineSpecialties: [MaestroWorkerDisciplineSpecialtyInput!]
		$workerDisciplines: [WorkerDisciplineInput!]
	) {
		updateWorker(
			workerId: $workerId
			workerAvailabilities: $workerAvailabilities
			availableStartDate: $availableStartDate
			preferredShifts: $preferredShifts
			addresses: $addresses
			licenseStates: $licenseStates
			workerDisciplineSpecialties: $workerDisciplineSpecialties
			workerDisciplines: $workerDisciplines
		) {
			id
		}
	}
`

export const CREATE_WORKER_CERTICATION_MUTATION = gql`
	mutation createWorkerCertification(
		$licenseDiscipline: String!
		$licenseState: String!
		$licenseNumber: String!
		$compactLicense: Boolean!
		$expirationDate: String!
		$certificationType: String!
		$licenseImages: [FileArgument!]!
	) {
		createWorkerCertification(
			licenseDiscipline: $licenseDiscipline
			licenseState: $licenseState
			licenseNumber: $licenseNumber
			compactLicense: $compactLicense
			expirationDate: $expirationDate
			certificationType: $certificationType
			licenseImages: $licenseImages
		) {
			...workerCertificationFragment
			licenseImages {
				...imageFileFragment
			}
		}
	}

	${WORKER_CERTIFICATION_FRAGMENT}
	${IMAGE_FILE_FRAGMENT}
`

export const DISCONNECT_SOCIAL_AUTHENTICATION = gql`
	mutation DisconnectSocialAuthentication($socialAuthenticationId: String!) {
		disconnectSocialAuthentication(socialAuthenticationId: $socialAuthenticationId) {
			success
		}
	}
`
export const CREATE_OR_UPDATE_SUPPORT_DOCUMENTS_MUTATION = gql`
	mutation($supportDocumentsAttributes: [SupportDocumentArgument!]!, $workerId: String!) {
		recruiterCreateOrUpdateSupportDocument(workerId: $workerId, supportDocumentsAttributes: $supportDocumentsAttributes) {
			id
			documentType
			document {
				id
				fileUrl
				filename
				contentType
			}
		}
	}
`
export const CREATE_OR_UPDATE_WORKER_SPECIALTY = gql`
	mutation($workerSpecialtiesAttributes: [WorkerSpecialtyArgument!]!, $workerId: String!) {
		recruiterCreateOrUpdateWorkerSpecialty(workerId: $workerId, workerSpecialtiesAttributes: $workerSpecialtiesAttributes) {
			id
			specialty
			completedChecklist
			category
			isPrimary
			workerSkillChecklistSections {
				id
				name
				workerSkillChecklists {
					id
					question
					proficiency
					frequency
				}
			}
		}
	}
`

export const DELETE_WORKER_SPECIALTY = gql`
	mutation($id: String!, $workerId: String!) {
		recruiterDestroyWorkerSpecialty(workerId: $workerId, id: $id) {
			success
			id
		}
	}
`
export const GET_SIGNED_URLS = gql`
	query getSignedUrls($blobIds: [String!]!) {
		signedUrls(blobIds: $blobIds) {
			id
			fileUrl
			blobId
			imageUrls(size: ["100x100", "200x200", "500x500"])
		}
	}
`
export const SEND_OTP_CODE_V2_MUTATION = gql`
	mutation sendOtpCodeV2Mutation($phoneNumber: String!, $recaptchaToken: String) {
		sendOtpCodeV2(phoneNumber: $phoneNumber, recaptchaToken: $recaptchaToken) {
			success
			worker {
				remainingExpirationTime
			}
		}
	}
`

export const SUPPORT_DOCUMENTS_MUTATION = gql`
	query supportDocumentsQuery($workerId: String!) {
		recruiterSupportDocuments(workerId: $workerId) {
			id
			documentType
			document {
				id
				fileUrl
				filename
				contentType
			}
		}
	}
`
export const WORKER_REACT_FEEDBACK_MACHING_MUTATION = gql`
	mutation($jobId: String!, $workerId: String!, $action: MaestroFeedbackMatchActionsEnum!) {
		feedbackWorkerMatch(jobId: $jobId, workerId: $workerId, action: $action) {
			success
		}
	}
`

export const UPDATE_WORKER_PREFERENCES_MUTATION = gql`
	mutation updateWorker($workerId: String!, $workingPreferredLocations: [MaestroWorkingPreferredLocationInput!], $availableStartDate: ISO8601Date) {
		updateWorker(workerId: $workerId, workingPreferredLocations: $workingPreferredLocations, availableStartDate: $availableStartDate) {
			id
		}
	}
`

export const UPDATE_WORKER_REFERENCES_MUTATION = gql`
	mutation updateWorker($workerId: String!, $workerReferences: [MaestroWorkerReferenceInput!]) {
		updateWorker(workerId: $workerId, workerReferences: $workerReferences) {
			id
		}
	}
`
