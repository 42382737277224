/// That file is a client-side file that will be loaded in the browser. It will be used to initialize the assistant and listen to the messages from the assistant.
import { observer } from 'mobx-react-lite'
import { useCallback, useState } from 'react'
import { authStore, routerStore } from './stores'
import { useInterval } from 'react-use'
import { useDebounceEffect } from 'ahooks'
import qs from 'query-string'

// Copy the action types from the assistant server-side file
const ACTION_TYPES = {
	ONBOARDING: 'onboarding',
	SEARCH_JOBS: 'search_jobs',
	TIMECARD: 'timecard',
	REGISTER_ACCOUNT: 'register_account',
	COMPLETE_PROFILE: 'complete_profile',
	CREATE_ACCOUNT_SUCCESSFULLY: 'create_account_successfully',
	CHECK_NEW_JOB_OPENINGS_AND_MATCHES: 'check_new_job_openings_and_matches',
	CHECK_ENDING_UPCOMING_ASSIGNMENTS: 'check_ending_upcoming_assignments',
	GET_CLINICIAN_DETAIL: 'get_clinician_detail',
	CHECK_EXPIRED_DOCUMENT_OR_CREDENTIALS: 'check_expired_document_or_credentials',
	REVIEW_AND_SUBMIT_JOB_SUBMISSIONS: 'review_and_submit_job_submissions',
	CHECK_NEWLY_ASSIGNED_CLINICIANS: 'check_newly_assigned_clinicians',
	IDENTIFY_POTENTIAL_CHURN_CLINICIANS: 'identify_potential_churn_clinicians',
	GET_JOB_DETAIL: 'get_job_detail',
	UNIDENTIFIED: 'unidentified',
}

// Override the navigateTo function

const ACTION_PARAMS = {
	WORKER_INFO: 'worker_info',
	WORKER_MATCHES: 'worker_matches',
	WORKER_ACTIVITIES: 'worker_activities',
	JOB_INFO: 'job_info',
	JOB_MATCHES: 'job_matches',
	JOB_SUBMISSIONS: 'job_submissions',
	JOB_ASSIGNMENTS: 'job_assignments',
}

/**
 *
 * @param {*} tab - tab name from the assistant
 *
 * @returns - the path name for the tab
 */
const mappingTabToPath = (tab) => {
	switch (tab) {
		case ACTION_PARAMS.WORKER_INFO:
			return '/profile'
		case ACTION_PARAMS.WORKER_MATCHES:
			return '/jobs'
		case ACTION_PARAMS.WORKER_ACTIVITIES:
			return '/activities'
		case ACTION_PARAMS.JOB_INFO:
			return '?tab=info'
		case ACTION_PARAMS.JOB_MATCHES:
			return '?tab=matches'
		case ACTION_PARAMS.JOB_SUBMISSIONS:
			return '?tab=submissions'
		case ACTION_PARAMS.JOB_ASSIGNMENTS:
			return '/active-assignments'
		default:
			return ''
	}
}

const navigateTo = (action) => {
	const args = action.action_params
	const queryString = qs.stringify({ ...args, action, from: 'ai' })
	const { tab } = args

	const subPath = mappingTabToPath(tab)

	switch (action.action_type) {
		case ACTION_TYPES.SEARCH_JOBS:
			routerStore.push(`/recruiter_jobs/available-jobs?${queryString}`)
			break
		case ACTION_TYPES.TIMECARD:
			routerStore.push(`/home/submission?${queryString}`)
			break
		case ACTION_TYPES.ONBOARDING:
			routerStore.push(`/home/submission?${queryString}`)
			break
		case ACTION_TYPES.CREATE_ACCOUNT_SUCCESSFULLY:
			routerStore.push(`/user_profile?${queryString}`)
			break
		case ACTION_TYPES.CHECK_NEW_JOB_OPENINGS_AND_MATCHES:
			routerStore.push(`/recruiter_jobs/matches?${queryString}`)
			break
		case ACTION_TYPES.CHECK_ENDING_UPCOMING_ASSIGNMENTS:
			routerStore.push(`/recruiter_jobs/active-assignments?${queryString}`)
			break
		case ACTION_TYPES.GET_CLINICIAN_DETAIL:
			const clinicianId = args?.clinicianId
			const candidateInfoPath = subPath ? subPath : '?tab=info'
			routerStore.push(clinicianId ? `/candidate_info/${clinicianId}${candidateInfoPath}` : `/candidate_info${candidateInfoPath}${queryString}`)
			break
		case ACTION_TYPES.CHECK_EXPIRED_DOCUMENT_OR_CREDENTIALS:
			break
		case ACTION_TYPES.REVIEW_AND_SUBMIT_JOB_SUBMISSIONS:
			routerStore.push(`/candidates/submission?${queryString}`)
			break
		case ACTION_TYPES.CHECK_NEWLY_ASSIGNED_CLINICIANS:
			routerStore.push(`/candidates/pre-submission?${queryString}`)
			break
		case ACTION_TYPES.IDENTIFY_POTENTIAL_CHURN_CLINICIANS:
			break
		case ACTION_TYPES.GET_JOB_DETAIL:
			const jobInfoPath = subPath ? subPath : '?tab=info'
			if (args?.workerId) {
				routerStore.push(args?.workerId ? `/candidate_info/${args?.workerId}${jobInfoPath}` : `/candidate_info${jobInfoPath}${queryString}`)
			} else {
				routerStore.push(args?.jobId ? `/job_info/${args?.jobId}${jobInfoPath}` : '/recruiter_jobs' + jobInfoPath + queryString)
			}
			break
		case 'unidentified':
			break
		default:
			break
	}
}

export const Assistant = observer(() => {
	/**
	 * Get the token from the auth store on client-side
	 * That means the client must be authenticated to use the assistant
	 * and use `mobx` to manage the state
	 * and must be defined the `authStore` in the `stores` file
	 */
	const { token } = authStore

	const [instance, setInstance] = useState(window.OpusAssistant)

	/**
	 * If you want to handle the messages from the assistant, you can do it here
	 */
	const onReceiveMessage = useCallback((data) => {
		console.debug('Received message from assistant:', data)
	}, [])

	/**
	 *  If you want to override the navigation, you can do it here
	 */
	const onReceiveAction = (data) => {
		navigateTo(data) // Default navigation
	}

	useInterval(
		() => {
			if (window.OpusAssistant) {
				setInstance(window.OpusAssistant)
			}
		},
		instance ? null : 1000
	)

	useDebounceEffect(
		() => {
			if (instance) {
				instance.init({
					baseUrl: 'https://opus-ai-assistant-maestro-qa.opusasia.io',
					token,
					onReceiveMessage,
					onReceiveAction,
					routerStore,
				})
			}
		},
		[instance, token, onReceiveMessage, onReceiveAction],
		{ wait: 1000, leading: false, trailing: true }
	)

	return null
})
