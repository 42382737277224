import React from 'react'
import { Switch, Redirect, Route as BaseRoute, useLocation } from 'react-router-dom'
import { observer } from '~/common/mobx.decorator'
import { Route } from './components/route'
import { FEATURES } from '~/features'
import { COMPANIES } from '~/companies'
import { PATHS } from './common/constants'
import { Tracking } from './tracking'
import { careStore } from '~/companies/recruiter/recruiter.store'
import { eventClient } from '@opus/web.core.lib.event-tracking'
import { isEmpty } from 'lodash'

export const AppRoute = observer(() => {
	let location = useLocation()

	React.useEffect(() => {
		const code = careStore.allFeatures?.code
		if (!isEmpty(code)) {
			eventClient.logScreenEvent(code)
		}
	}, [location])

	return (
		<Switch>
			<BaseRoute
				exact
				sensitive
				path="/:url([a-z/]*[A-Z]+[a-z/]*)/"
				render={(props) => <Redirect to={{ pathname: `${props.location.pathname}`.toLowerCase() }} />}
			/>
			<Route path={PATHS.common.auto_submission_feedback} component={FEATURES['auto-submission-feedback']} />
			<Route path={PATHS.common.unsubscribe_emails} component={FEATURES['unsubscribe-email']} />
			<Route path={PATHS.common.sso_authentication} component={FEATURES['common-sso']} />
			<Route path={PATHS.common.tracking} component={Tracking} />
			<Route path={PATHS.common.errors} component={FEATURES['common-errors']} />
			<Route path={PATHS.common.signin} component={FEATURES['common-signin']} />
			<Route path={PATHS.common.reset} component={FEATURES['common-reset']} />
			<Route path={PATHS.common.home} component={COMPANIES.recruiter} />
		</Switch>
	)
})
