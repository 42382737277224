import React from 'react'
import { useSnackbar } from 'notistack'
import { observer, autorun } from '~/common/mobx.decorator'
import { notifyStore } from '~/stores'
import { Button } from '~/components/button'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { NotifyDialog } from './notify-dialog.component'
import { defaultTo } from 'lodash'
import { Typography } from '../typography'
import { SnackbarCustomization } from '~/components/snackbar'

export const GlobalNotify = observer(() => {
	const { t } = useTranslation()
	const { waitDialogs, doneDialogs, waitMessages, doneMessages } = notifyStore

	const { enqueueSnackbar, closeSnackbar } = useSnackbar()
	autorun(() => {
		waitMessages.forEach((note) => {
			const { key, message, options } = note
			if (doneMessages[key]) {
				return
			}

			const actions = options.persist ? [...options.actions, { label: 'DISMISS' }] : options.actions

			enqueueSnackbar(t(message), {
				key: message, // Workaround solution for ticket: https://mylong.atlassian.net/browse/AHS-1245
				...(options?.customization && {
					content: (key) => <SnackbarCustomization variant={options?.variant} key={key} message={typeof message === 'string' ? t(message) : message} />,
				}),
				...options,
				preventDuplicate: true,
				action: () =>
					actions?.map(({ onClick, label, keepSnackbar, ...rest }) => {
						const handleClick = () => {
							if (typeof onClick === 'function') {
								onClick()
							}

							if (!keepSnackbar) {
								closeSnackbar(key)
							}
						}

						return (
							<Button key={key} {...rest} onClick={handleClick}>
								{t(label)}
							</Button>
						)
					}),
			})

			notifyStore.closeMessage(key)
		})
	})

	return waitDialogs.map(({ key, message, options }) => (
		<NotifyDialog
			key={message}
			title={options?.title}
			okText={options?.okText}
			cancelText={options?.cancelText}
			okProps={options?.okProps}
			cancelProps={options?.cancelProps}
			open={defaultTo(doneDialogs[key], true)}
			onOk={() => {
				if (typeof options?.onOk === 'function') {
					options.onOk(key)
				}
				notifyStore.closeDialog(key)
			}}
			showButtonClose={options?.showButtonClose}
			onClose={() => options?.onClose?.(key)}
			onCancel={options?.onCancel ? () => options?.onCancel?.(key) : undefined}
		>
			{options?.children ? (
				typeof options.children === 'function' ? (
					options?.children({ key })
				) : (
					options?.children
				)
			) : (
				<Typography whiteSpace="pre-line" align="left" color="content.dark">
					{t(message)}
				</Typography>
			)}
		</NotifyDialog>
	))
})
