import gql from 'graphql-tag'
import { JOB_FRAGMENT } from '../apollo.fragment'

export const APPLY_JOB_MUTATION = gql`
	mutation applyJob($jobId: String!) {
		createJobApplicant(jobId: $jobId, status: apply, source: opus_web_portal) {
			id
			status
		}
	}
`

export const FAVORITE_JOB_MUTATION = gql`
	mutation favoriteOrUnfavoriteAJobMutation($jobId: String!, $action: FavoriteActionEnum!) {
		favoriteOrUnfavoriteAJob(jobId: $jobId, action: $action) {
			...jobFragment
		}
	}
	${JOB_FRAGMENT}
`

export const JOB_REACT_FEEDBACK_MACHING_MUTATION = gql`
	mutation($jobId: String!, $workerId: String!, $action: FeedbackMatchActionsEnum!) {
		feedbackJobMatch(jobId: $jobId, workerId: $workerId, action: $action) {
			success
		}
	}
`
