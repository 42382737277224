import { Button, withStyles } from '@material-ui/core'

export const StyledSpecialButton = withStyles((theme) => ({
	root: {
		'&.outlinedError': {
			color: 'red',
			border: `1px solid ${theme.palette.error.medium}`,
			'&:hover': {
				backgroundColor: theme.palette.error.main,
				color: theme.colors.content.veryLight,
			},
		},
	},
}))(Button)
