import { CANDIDATE_TABS } from '~/common/constants/tab.constant'

export const PATHS = {
	// COMMON
	common: {
		home: '/',
		reset: '/reset',
		signin: '/signin',
		errors: '/errors',
		change_password: '/change_password',
		api: '/maestro/api/v1',
		public_graphql: '/api/graphql/public',
		graphql: '/graphql',
		recruiter: '/recruiter',
		signup: '/signup',
		tracking: '/tracking',
		sso_authentication: '/authentication',
		unsubscribe_emails: '/unsubscribe_emails',
		auto_submission_feedback: '/auto_submission_confirmation',
	},
	// Recruiter Portal
	recruiter: {
		home: '/home',
		role: '/role',
		view_as_candidate: '/view_as_candidate',
		candidates: '/candidates',
		find_job: '/find_job',
		profile: '/profile',
		user_profile: '/user_profile',
		my_jobs: '/my_jobs',
		activation: '/activation',
		timecards: '/timecards',
		popular_cities: '/popular_cities',
		suggested_jobs: '/suggested_jobs',
		recruiter_jobs: '/recruiter_jobs',
		explore_matches: '/page/explore_matches',
		trending_jobs: '/page/trending_jobs',
		urgent_needs: '/page/urgent_needs',
		last_viewed: '/page/last_viewed',
		assigments: '/assigments',
		report: '/report',
		jobs: '/jobs',
		toas: '/toas',
		job_info: '/job_info',
		candidate_info: '/candidate_info',
		job_applicants: '/job_applicants',
		notification: '/notification',
		candidates_assignments: `/candidates/${CANDIDATE_TABS.on_assignment}`,
		candidates_submission: `/candidates/${CANDIDATE_TABS.submission}`,
		candidates_matches: `/candidates/${CANDIDATE_TABS.matches}`,
		candidates_pre_submissions: `/candidates/${CANDIDATE_TABS.pre_submission}`,
		candidates_submissions_ready: `/candidates/${CANDIDATE_TABS.submission_ready}`,
	},
}
