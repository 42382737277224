import { gql } from '@apollo/client'
import { EMPLOYER_FRAGMENT, MAESTRO_EMPLOYER_FRAGMENT } from '~/common/apollo/apollo.fragment'

export const SIGNIN_EMPLOYER_MUTATION = gql`
	mutation signInEmployer($email: String!, $password: String!, $recaptchaToken: String) {
		signInEmployer(email: $email, password: $password, recaptchaToken: $recaptchaToken) {
			authToken {
				accessToken
				expiresIn
				refreshToken
				tokenType
			}
			notificationChannel
			employer {
				...employerFragment
			}
		}
	}

	${EMPLOYER_FRAGMENT}
`

export const GET_RECRUITER_PROFILE = gql`
	query getRecruiterProfile($id: String!) {
		recruiterProfile(id: $id) {
			...maestroEmployerFragment
		}
	}

	${MAESTRO_EMPLOYER_FRAGMENT}
`
