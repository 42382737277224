import axios from 'axios'
import { nanoid } from 'nanoid'
import { captureException } from '~/common/helpers'
import { action, computed, observable, persist, storage, store } from '~/common/mobx.decorator'
import { authStore } from './auth.store'
import { THEMES } from '~/themes'
import { apolloClient, GET_APP_INIT_QUERY, GET_USER_INFO_QUERY } from '~/common/apollo'

axios.interceptors.response.use(undefined, (error) => {
	captureException('Axios Client', error)
	return Promise.reject(error)
})

@store({ persist: true })
class AppStore {
	constructor() {
		storage.ready(() => {
			this.ready = true
			if (!this._uniqueId) {
				this._uniqueId = nanoid()
			}
		})
	}

	@persist @observable _uniqueId
	@observable config = null
	@observable ready = false

	@observable liveChatId
	@observable meta_domain

	@observable appConfig

	@observable tenantId = ''
	@observable apiUrl = ''

	@action
	setTenantId = (tenantId) => {
		this.tenantId = tenantId
		sessionStorage.setItem('tenantId', tenantId)
	}

	@action
	setApiUrl = (apiUrl) => {
		this.apiUrl = apiUrl
		sessionStorage.setItem('apiUrl', apiUrl)
		axios.defaults.baseURL = `${apiUrl}/api/public/v1`
	}

	@computed
	get isEnableAA() {
		return this.appConfig?.tenantConfig?.enableOpusAiAssistant || false
	}

	@computed
	get tenantConfig() {
		return this.appConfig.tenantConfig || {}
	}

	@computed
	get enableViewAsCandidate() {
		if (this.appConfig) {
			const viewAsCandidateFeature = this.appConfig?.featureFlags?.find((feature) => feature.feature === 'view_as_candidate')
			if (viewAsCandidateFeature && viewAsCandidateFeature.enabled) {
				return true
			}
		}
		return false
	}

	@computed
	get enableEmployeeLogout() {
		if (this.appConfig) {
			const employeeLogoutFeature = this.appConfig?.featureFlags?.find((feature) => feature.feature === 'employer_logout')
			if (employeeLogoutFeature && employeeLogoutFeature.enabled) {
				return true
			}
		}
		return false
	}

	@computed
	get enableDeleteResume() {
		if (this.appConfig) {
			const deleteResumeFeature = this.appConfig?.featureFlags?.find((feature) => feature.feature === 'delete_resume')
			if (deleteResumeFeature && deleteResumeFeature.enabled) {
				return true
			}
		}
		return false
	}

	@computed
	get enableWorkerDisciplineSpecialties() {
		if (this.appConfig?.tenantConfig) {
			return this.appConfig?.tenantConfig?.specialtyAssociateWithDiscipline
		}
		return false
	}

	@computed
	get enableChangeEmployeeRole() {
		if (this.appConfig) {
			const changeEmployeeRoleFeature = this.appConfig?.featureFlags?.find((feature) => feature.feature === 'change_employer_role')
			if (changeEmployeeRoleFeature && changeEmployeeRoleFeature.enabled) {
				return true
			}
		}
		return false
	}

	@computed
	get enableSystemNotificationSettings() {
		if (this.appConfig) {
			const systemNotificationSettingsFeature = this.appConfig?.featureFlags?.find((feature) => feature.feature === 'system_setting_notifications')
			if (systemNotificationSettingsFeature && systemNotificationSettingsFeature.enabled) {
				return true
			}
		}
		return false
	}
	/* Company Id  */
	@computed
	get id() {
		return process.env.REACT_APP_COMPANY_ID
	}

	@computed
	get name() {
		return this.appConfig?.tenant?.name || process.env.REACT_APP_COMPANY_NAME
	}

	@computed
	get logo() {
		if (this.appConfig?.tenant?.logo?.file?.fileUrl) {
			return this.appConfig?.tenant?.logo?.file?.fileUrl
		}
		return `${process.env.PUBLIC_URL}/assets/defaultLogo.png`
	}

	@computed
	get logos() {
		return {
			logo: this.logo,
			logoClient: `${process.env.PUBLIC_URL}/assets/logoClient.png?version=${process.env.REACT_APP_CI_COMMIT_TAG}`,
			logiMini: `${process.env.PUBLIC_URL}/assets/logo-mini.png?version=${process.env.REACT_APP_CI_COMMIT_TAG}`,
		}
	}
	@computed
	get getTheme() {
		return THEMES.aequor
	}
	@action
	init = async () => {
		await Promise.allSettled([this.fetchFEConfig()]).catch(console.error)

		setTimeout(() => {
			authStore.verifyToken()
		}, 1000)
	}

	fetchFEConfig = async () => {
		const response = await fetch('/app-config.json')
		return await response.json()
	}

	@action
	fetchAppConfig = async () => {
		try {
			const response = await apolloClient().query({
				query: GET_APP_INIT_QUERY,
			})
			this.appConfig = response.data?.appInit || {}
		} catch (e) {
			console.error(e)
			throw e
		}
	}

	@action
	getProfileInfo = async () => {
		try {
			if (authStore.authorization) {
				const data = await apolloClient().query({
					query: GET_USER_INFO_QUERY,
				})

				if (data?.employer) {
					this.profile = data.employer
					this.roles = data.employer?.roles
				}
			}
		} catch (err) {
			console.error(err)
		}
	}

	@action
	fetchTenantConfig = async () => {
		const isDevelopment = window.location.hostname === 'localhost'
		const isGHR = process.env.REACT_APP_LOCAL_COMPANY_NAME === 'GHR'
		try {
			const headerConfig = isDevelopment
				? {
						headers: {
							'tenant-id': process.env.REACT_APP_DEFAULT_TENANT_ID || '',
							Accept: 'application/json',
						},
				  }
				: {
						headers: {
							Accept: 'application/json',
						},
				  }

			try {
				const response = await axios.get(`${process.env.REACT_APP_PROXY_API_URL}/api/public/v1/connections`, headerConfig)
				if (response.data) {
					this.setTenantId(response.data.tenantId)
					if (isDevelopment && isGHR) {
						this.setApiUrl('https://ghr-backend-qa.opusasia.io')
					} else {
						this.setApiUrl(`https://${response.data.apiDomain}`)
					}
				}
			} catch (e) {
				console.error(e)
			}
		} catch (e) {
			console.error(e)
		}
	}
}

export const appStore = new AppStore()
