import React from 'react'
export const FEATURES = {
	// "care-activation": React.lazy(() => import("~/features/care-activation")),
	'care-popular-cities': React.lazy(() => import('~/features/care-popular-cities')),
	'care-find-job': React.lazy(() => import('~/features/care-find-job')),
	'care-suggested-jobs': React.lazy(() => import('~/features/care-suggested-jobs')),
	'care-my-jobs': React.lazy(() => import('~/features/care-my-jobs')),
	'care-jobs': React.lazy(() => import('~/features/care-jobs')),

	//RECRUITER FEATURES
	'recruiter-role': React.lazy(() => import('~/features/recruiter-role')),
	'recruiter-home': React.lazy(() => import('~/features/recruiter-home')),
	'recruiter-jobs': React.lazy(() => import('~/features/recruiter-jobs')),
	'recruiter-candidates': React.lazy(() => import('~/features/recruiter-candidates')),
	'recruiter-assignments': React.lazy(() => import('~/features/recruiter-assignments')),
	'recruiter-timecards': React.lazy(() => import('~/features/recruiter-timecards')),
	'recruiter-job-info': React.lazy(() => import('~/features/recruiter-job-info')),
	'recruiter-candidate-info': React.lazy(() => import('~/features/recruiter-candidate-info')),
	'recruiter-toas': React.lazy(() => import('~/features/recruiter-toas')),
	'recruiter-report': React.lazy(() => import('~/features/recruiter-report')),
	'recruiter-profile': React.lazy(() => import('~/features/recruiter-profile')),
	notification: React.lazy(() => import('~/features/care-notification')),

	// COMMON FEATURES
	'auto-submission-feedback': React.lazy(() => import('~/features/auto_submission_feedback')),
	'common-sso': React.lazy(() => import('~/features/common-single-sign-on')),
	'common-signin': React.lazy(() => import('~/features/common-signin')),
	'common-errors': React.lazy(() => import('~/features/common-errors')),
	'common-reset': React.lazy(() => import('~/features/common-reset')),
	'common-change-password': React.lazy(() => import('~/features/common-change-password')),

	// Communication
	'unsubscribe-email': React.lazy(() => import('~/features/common-email/unsubscribe-email')),
}
