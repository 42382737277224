import React, { useCallback } from 'react'
import { CircularProgress, Button as MuiButton } from '@material-ui/core'
import { eventClient } from '@opus/web.core.lib.event-tracking'
import { StyledSpecialButton } from './button.styles'

export const Button = ({ loading, children, disabled, startIcon, ...props }) => {
	const handleClick = useCallback(() => {
		if (props.onTrackingEvent) {
			props.onTrackingEvent()
		} else if (props.eventId) {
			eventClient.logClickEvent(props.eventId, props.eventParams).catch((error) => console.log(`Error tracking data ${props.eventId}`, error.message))
		}
	}, [props])

	return (
		<MuiButton disabled={loading || disabled} startIcon={loading ? <CircularProgress size={20} /> : startIcon} onClick={handleClick} {...props}>
			{children}
		</MuiButton>
	)
}

Button.defaultProps = {
	fullWidth: true,
	color: 'primary',
	variant: 'contained',
	size: 'large',
}

/** Special variants of the Button that not supported in `material-ui/core` version 4.x */
export const OutlinedButton = (props) => {
	return <StyledSpecialButton {...props} />
}
