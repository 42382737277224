import flatten, { unflatten } from 'flat'
import { pickBy, isUndefined, get, join } from 'lodash'
import parsePlace from 'parse-google-place'

export const trimData = (values, pickFunc = (value) => !isUndefined(value)) => {
	return unflatten(pickBy(flatten(values), pickFunc))
}

export const parseAddress = (place) => {
	const data = parsePlace(place)

	const location = place?.geometry?.location?.toJSON()

	return {
		city: data.city,
		country: data.countryLong || data.countryShort,
		countryShort: data?.countryShort,
		latitude: location?.lat,
		longitude: location?.lng,
		street: data?.address,
		zipcode: data?.zipCode,
		state: data?.city === data?.stateLong && data.countryShort !== 'US' ? data.countryLong : data?.stateLong || data.stateShort,
		stateShort: data?.stateShort,
		formatted_address: place?.formatted_address,
		countryCode: data?.countryCode,
	}
}

export const parseLocation = (data) => {
	return {
		name: data?.name,
		city: data?.name,
		country: data?.countryName,
		countryShort: data?.countryCode,
		countryCode: data?.countryCode,
		latitude: data?.latitude ? parseFloat(data?.latitude) : undefined,
		longitude: data?.longitude ? parseFloat(data?.longitude) : undefined,
		state: data?.stateName,
		stateCode: data?.stateCode,
		fullAddress: data?.fullAddress,
	}
}

export const parseAddressToView = (address, fields = ['city', 'state']) => join(fields.map((field) => get(address, field)).filter(Boolean), ', ')

export const parseLocationWIP = (data) => {
	const { id, country_code, country_id, latitude, longitude, name, state_code, state_id } = data
	return {
		id,
		countryCode: country_code,
		countryId: country_id,
		latitude: parseFloat(latitude),
		longitude: parseFloat(longitude),
		name,
		stateCode: state_code,
		stateId: state_id,
		fullAddress: `${name}, ${state_code}, ${country_code}`,
	}
}

export const parseState = (data) => {
	const { id, name, country_id, country_code, state_code, latitude, longitude } = data

	return {
		id,
		name,
		countryId: country_id,
		countryCode: country_code,
		stateCode: state_code,
		latitude: parseFloat(latitude),
		longitude: parseFloat(longitude),
	}
}
