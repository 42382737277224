import { css, createGlobalStyle } from 'styled-components'
import { makeStyles } from '@material-ui/core'

export const GlobalStyle = createGlobalStyle`
	body {
		color: ${({ theme }) => theme.palette.text.primary};
		background-color: #f8f8f8
	}
	
	input:-webkit-autofill,
	input:-webkit-autofill:hover, 
	input:-webkit-autofill:focus, 
	input:-webkit-autofill:active  {
		box-shadow: 0 0 0 30px white inset !important;
	}
	.grecaptcha-badge {
		display: none;
	}

  .MuiButton-contained.Mui-disabled {
    color: #ffff
  }

  .MuiSelect-select:focus {
    background-color: transparent;
  }
  .MuiAutocomplete-inputRoot[class*="MuiInput-root"] {
    padding-bottom: 0;
  }
	.MuiAutocomplete-popper {
		padding: 5px 0;
		> div {
			max-height: 300px;
			overflow: auto;
			
			> ul {
				max-height: inherit;
			}
		}
	}
  .MuiInputBase-root {
    padding-bottom: 0;
  }

	.pac-container {
		z-index: 3000;
	}
    form label.MuiFormLabel-root.Mui-error {
      color: ${({ theme }) => theme.palette.text.tertiary};
    }
	.MuiInputBase-input {
		padding: 6px 0 16px;
	}
	.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child {
		padding: 6px 0 16px;
	}
`

export const linearProgressStyle = css`
	position: fixed;
	top: 0;
	width: 100vw;
	height: 3px;
	z-index: ${({ theme }) => theme.zIndex.drawer + 1};
`

export const useStyles = makeStyles(() => ({
	root: {
		'& div[class*="contentRoot"]': {
			borderRadius: '1rem',
			fontSize: '14px',
			fontStyle: 'normal',
			fontWeight: 600,
		},
		'& div[class*="variantSuccess"]': {
			color: '#3BB700 !important',
			backgroundColor: 'white !important',
		},
	},
}))
