import { gql } from '@apollo/client'

export const EMERGENCY_CONTACT_FRAGMENT = gql`
	fragment emergencyContactFragment on MaestroWorkerEmergencyContact {
		externalId
		firstName
		id
		lastName
		phone
		relationship
	}
`

export const WORKER_ADDRESS_FRAGMENT = gql`
	fragment workerAddressFragment on MaestroWorkerAddress {
		id
		zipcode
		street
		city
		state
		country
		aptNumber
	}
`

export const WORKER_FRAGMENT = gql`
	fragment workerFragment on MaestroWorker {
		id
		addresses {
			street
			city
			state
			country
			default
			zipcode
			id
		}
		resumes {
			id
			file {
				fileUrl
				filename
				contentType
				blobId
			}
		}
		workerEmergencyContacts {
			firstName
			lastName
			phone
			relationship
		}
		phone
		engagementScore
		matchingPercentage
		profilePercentageCompleted
		agreedToTerms
		availableStartDate
		compactLicense
		createdAt
		email
		externalCreatedAt
		firstName
		isActiveStatus
		lastName
		fullName
		phone
		phoneVerifiedAt
		status
		internalSub
		externalSub
		jobApplicantsCount
		activeSubmittedJobsCount
		noTalentMatches
		licenseStates
		user {
			id
			username
			lastSignInAt
		}
		avatar {
			id
			file {
				fileUrl
			}
		}
		workAuthorizedAt
		zipcode
		tags {
			id
			name
		}
		workerDisciplineSpecialties {
			id
			discipline {
				disciplineName
				id
			}
			specialty {
				name
				id
				hasSkillChecklist
			}
			isPrimary
		}
		workerDisciplines {
			id
			discipline {
				disciplineName
				id
			}
		}
		preferredShifts
		workerAvailabilities {
			id
			fromDatetime
			status
			toDatetime
		}
	}
`

export const LOCATION_FRAGMENT = gql`
	fragment locationFragment on MaestroLocation {
		id
		address
		addressState
		city
		country
		zipcode
	}
`

export const ACTIVE_JOB_FRAGMENT = gql`
	fragment jobFragment on MaestroJob {
		id
		title
		externalJobId
		startDate
		duration
		matchingPercentage
		weeklyPayAmount
		minWeeklyPayAmount
		hourlyPayHigh
		hourlyPayLow
		stipends
		taxableHigh
		taxableLow
		specialty
		hospital
		discipline
		isFavorited
		status
		expiredDate
		locationImage {
			thumbnailImage {
				imageUrls(size: ["100x100"])
			}
			webImage {
				fileUrl
			}
		}
		location {
			...locationFragment
		}
		jobApplicant {
			id
			status
			workerAssignment {
				endDate
			}
		}
		shifts
		hoursPerWeek
		shiftsPerWeek
		externalJobId
		licensesRequired
		licenseStateRequired
		certificationsRequired
	}
	${LOCATION_FRAGMENT}
`

export const JOB_FRAGMENT = gql`
	fragment jobFragment on MaestroJob {
		location {
			region
			city
			zipcode
			addressState
		}
		locationImage {
			thumbnailImage {
				imageUrls(size: ["100x100"])
			}
			webImage {
				fileUrl
			}
		}
		customStartDate
		applicantCount
		createdAt
		discipline
		duration
		facilityType
		hospital
		frequency
		id
		jobType
		externalCreatedAt
		matchingPercentage
		minWeeklyPayAmount
		shifts
		skillLongName
		specialty
		startDate
		status
		title
		updatedAt
		isAsap
		viewCount
		weeklyPayAmount
		slotsNumber
	}
`

export const JOB_DETAIL_GENERAL_FRAGMENT = gql`
	fragment jobDetailGeneralFragment on MaestroJobGeneral {
		disciplines {
			disciplineName
			id
			disciplineType
		}
		jobGeneralSpecialties {
			id
			specialtyId
			disciplineId
			perDiemPayRate
			perDiemPayRateType
		}
		address {
			id
			country
			state
			city
			latitude
			zipcode
			longitude
			locationImage {
				webImage {
					fileUrl
				}
			}
		}
		benefitsText
		certificationsRequired
		description
		externalId
		facility {
			id
			name
		}
		hotJob
		id
		isPerDiem
		licenseStateCompact
		licenseStateRequired
		payCurrency
		settings
		title
		workTypes
		specialties {
			externalId
			id
			jobsCount
			longName
			name
		}
	}
`

export const RECRUITER_JOB_BASE_FRAGMENT = gql`
	fragment recruiterJobBaseFragment on MaestroJobBase {
		benefitsText
		billRate
		customFields
		externalFriendlyId
		isPerDiemLongTerm
		duration
		durationType
		endDatetime
		externalId
		hoursPerDay
		hoursPerWeek
		timezone
		id
		isFavorited
		atsSource
		metadata
		jobGeneral {
			...jobDetailGeneralFragment
		}
		matchingPercentage
		payAmountMax
		payAmountMin
		publishedAt
		payRate
		payType
		requirementsText
		shifts
		slotsNumber
		startDatetime
		viewedCount
		status
		jobApplicant {
			status
		}
		workerAssignment {
			id
			status
			payRate
			payType
			salary
		}
	}
	${JOB_DETAIL_GENERAL_FRAGMENT}
`

export const RECRUITER_JOB_FRAGMENT = gql`
	fragment recruiterJobFragment on MaestroJob {
		duration
		durationType
		endDatetime
		hoursPerDay
		hoursPerWeek
		timezone
		id
		isFavorited
		atsSource
		jobGeneral {
			...jobDetailGeneralFragment
		}
		duration
		matchingPercentage
		payAmountMax
		payAmountMin
		publishedAt
		payRate
		payType
		requirementsText
		shifts
		slotsNumber
		startDatetime
		viewedCount
		status
		jobApplicant {
			status
		}
		workerAssignment {
			id
			status
			payRate
			payType
			salary
		}
	}
	${JOB_DETAIL_GENERAL_FRAGMENT}
`

export const COMPANY_FRAGMENT = gql`
	fragment companyFragment on MaestroCompany {
		address
		addressState
		city
		country
		description
		id
		mainContactEmail
		mainContactName
		companyConfig {
			enableHourlySearch
		}
		name
		phone
		zip
	}
`

export const WORKER_CREDENTIAL_FRAGMENT = gql`
	fragment workerCredentialFragment on MaestroWorkerCredential {
		company {
			...companyFragment
		}
		createdAt
		expirationDate
		id
		name
		status
		worker {
			...workerFragment
		}
	}
	${WORKER_FRAGMENT}
	${COMPANY_FRAGMENT}
`

export const WORKING_AUTHORIZATION_FRAGMENT = gql`
	fragment workingAuthorizationFragment on MaestroWorkingAuthorization {
		id
		dateOfBirth
		externalId
		legalRightToWork
		npi
		ssn
		ssnMasked
	}
`

export const WORK_EXPERIENCE_FRAGMENT = gql`
	fragment workExperienceFragment on MaestroWorkExperience {
		id
		agency
		city
		country
		currentlyEmployed
		endDate
		externalId
		facilityName
		notes
		reasonForLeaving
		state
		startDate
		stateName
		title
		unitType
		unitDescription
		traumaLevel
		teachingFacility
		numberOfBeds
	}
`

export const SALESFORCE_INTEGRATION_FRAGMENT = gql`
	fragment salesforceIntegrationFragment on SalesforceIntegration {
		id
		companyId
		businessDay
		weekending
	}
`

export const ONBOARDING_PART_FRAGMENT = gql`
	fragment onboardingPartFragment on OnboardingPart {
		id
		name
		description
	}
`
export const DIRECT_UPLOAD_FRAGMENT = gql`
	fragment directUploadFragment on MaestroDirectUpload {
		blobId
		headers
		preSignedUrl
		signedBlobId
		url
	}
`

export const WORKER_CERTIFICATION_FRAGMENT = gql`
	fragment workerCertificationFragment on MaestroWorkerCertification {
		certificationFiles {
			documentType
			id
			note
			file {
				fileUrl
			}
		}
		certificationName
		doesNotExpire
		effectiveDate
		externalId
		id
		number
	}
`

export const WORKER_REFERENCE_FRAGMENT = gql`
	fragment workerReferenceFragment on MaestroWorkerReference {
		id
		city
		country
		email
		externalId
		facilityName
		firstName
		fromDate
		jobTitle
		lastName
		phone
		referenceType
		relationship
		state
		stateName
		toDate
		waitToContact
		zipcode
	}
`
export const IMAGE_FILE_FRAGMENT = gql`
	fragment imageFileFragment on File {
		id
		fileUrl
		contentType
	}
`

export const FILE_FRAGMENT = gql`
	fragment fileFragment on File {
		id
		filename
		fileUrl
		contentType
	}
`

export const WORKER_EDUCATION_FRAGMENT = gql`
	fragment workerEducationFragment on MaestroWorkerEducation {
		id
		schoolName
		state
		city
		country
		degreeName
		graduationDate
	}
`

export const WORKER_DISCIPLINE_SPECIALTY_FRAGMENT = gql`
	fragment workerDisciplineSpecialtyFragment on MaestroWorkerDisciplineSpecialty {
		completedAllScl
		discipline {
			id
			disciplineName
			specialties {
				id
				name
			}
		}
		hasSkillChecklist
		id
		isPrimary
		specialty {
			id
			name
		}
	}
`

export const WORKER_CETIFICATION_OVERVIEW_FRAGMENT = gql`
	fragment workerCertificationOverviewFragment on WorkerCertificationOverview {
		id
		workerCertifications {
			...workerCertificationFragment
		}
	}

	${WORKER_CERTIFICATION_FRAGMENT}
`

export const WORKER_REFERENCE_OVERVIEW_FRAGMENT = gql`
	fragment workerReferenceOverviewFragment on WorkerReferenceOverview {
		id
		workerReferences {
			...workerReferenceFragment
		}
	}

	${WORKER_REFERENCE_FRAGMENT}
`

export const WORKER_EDUCATION_OVERVIEW_FRAGMENT = gql`
	fragment workerEducationOverviewFragment on WorkerEducationOverview {
		id
		workerEducations {
			...workerEducationFragment
		}
	}

	${WORKER_EDUCATION_FRAGMENT}
`

export const WORK_EXPERIENCE_OVERVIEW_FRAGMENT = gql`
	fragment workExperienceOverview on MaestroWorkExperience {
		id
		agency
		city
		country
		currentlyEmployed
		endDate
		externalId
		facilityName
		notes
		reasonForLeaving
		state
		startDate
		stateName
		title
		travelAssignment
		unitType
	}
`

export const WORKER_ONBOARDING_PART_INTEGRATION_FRAGMENT = gql`
	fragment workerOnboardingPartIntegrationFragment on WorkerOnboardingPartIntegration {
		... on UserTermsOfService {
			id
			agreedAt
			termsOfService {
				id
				url
				version
			}
		}
		... on ResumeOnboarding {
			id
			worker {
				resumes {
					blobId
					id
					filename
					fileUrl
					contentType
					thumbnails
					imageUrls(size: ["100x100", "200x200", "500x500"])
				}
			}
		}
		... on SpecialtyOnboarding {
			id
			workerSpecialties {
				category
				id
				isPrimary
				completedChecklist
				specialty
				specialtyLongName
				workerSkillChecklistSections {
					id
					name
					workerSkillChecklists {
						frequency
						proficiency
						question
						id
					}
				}
			}
		}
		... on WorkingPreferredLocationOnboarding {
			id
			worker {
				id
				availableStartDate
			}
			workingPreferredLocations {
				id
				preferredWorkingState
				preferredWorkingCity
			}
		}

		... on WorkerReferenceOnboarding {
			id
			workerReferences {
				id
				facilityName
				contactEmail
				contactFullName
				contactFirstName
				contactLastName
				contactPhone
				jobTitle
				referenceType
				relationship
				salutation
				state
				city
				workedFrom
				workedTo
			}
		}
	}
`

export const WORKER_ONBOARDING_PART_FRAGMENT = gql`
	fragment workerOnboardingPartFragment on WorkerOnboardingPart {
		id
		integrationConfigurations
		integrationObject
		state
		onboardingPart {
			id
			name
		}
		integration {
			...workerOnboardingPartIntegrationFragment
		}
	}

	${WORKER_ONBOARDING_PART_INTEGRATION_FRAGMENT}
`

export const REFERRED_TRAVELER_FRAGMENT = gql`
	fragment referredTravelerFragment on ReferredTraveler {
		id
		firstName
		lastName
		email
		phone
		relationship
		discipline
	}
`

export const RECRUITER_FRAGMENT = gql`
	fragment recruiterFragment on Recruiter {
		companyId
		email
		id
		name
		phone
		title
		changePasswordRequired
	}
`

export const EMPLOYER_FRAGMENT = gql`
	fragment employerFragment on Employer {
		email
		id
		firstName
		lastName
		phone
		user {
			changePasswordRequired
			id
			tempPasswordExpiredAt
		}
		roles {
			id
			name
			scope
		}
	}
`

export const MAESTRO_EMPLOYER_FRAGMENT = gql`
	fragment maestroEmployerFragment on MaestroEmployer {
		email
		id
		firstName
		lastName
		phone
		user {
			changePasswordRequired
			id
			tempPasswordExpiredAt
		}
		roles {
			id
			name
			scope
		}
	}
`

export const JOB_APPLICANT_FRAGMENT = gql`
	fragment jobApplicantFragment on MaestroJobApplicant {
		createdAt
		id
		status
		updatedAt
		stage
		stageChangedAt
		translatedStatus
		job {
			...jobFragment
		}
		worker {
			...workerFragment
		}
	}
	${WORKER_FRAGMENT}
	${JOB_FRAGMENT}
`

export const WORKED_SHIFT_FRAGMENT = gql`
	fragment workedShiftFragment on WorkedShift {
		id
		workDate
		taskExternalId

		startTime
		endTime

		firstBreakStartTime
		firstBreakEndTime

		secondBreakStartTime
		secondBreakEndTime
	}
`

export const WORKED_EXPENSE_FRAGMENT = gql`
	fragment workedExpenseFragment on WorkerExpense {
		id
		category
		amount
		notes
		receiptImages {
			id
			filename
			fileUrl
		}
	}
`

export const WORKED_TIMESHEET_FRAGMENT = gql`
	fragment workedTimesheetFragment on WorkedTimesheet {
		id
		notes
		startDate
		endDate
		preEmploymentHours
		status
		workedShiftsOverview
	}
`

export const RECRUITER_WORKER_ONBOARDING_PART_INTEGRATION_FRAGMENT = gql`
	fragment workerOnboardingPartIntegrationFragment on WorkerOnboardingPartIntegration {
		... on WorkerCertificationOverview {
			...workerCertificationOverviewFragment
		}

		... on WorkExperienceOverview {
			...workExperienceOverview
		}

		... on WorkerReferenceOverview {
			...workerReferenceOverviewFragment
		}

		... on WorkerEducationOverview {
			...workerEducationOverviewFragment
		}

		... on UserTermsOfService {
			id
			agreedAt
			termsOfService {
				id
				url
				version
			}
		}

		... on CombinationOnboardingPart {
			id
			workerAddress {
				...workerAddressFragment
			}

			workingPreferredLocations {
				id
				preferredWorkingState
			}
		}
	}

	${WORKER_CETIFICATION_OVERVIEW_FRAGMENT}
	${WORK_EXPERIENCE_OVERVIEW_FRAGMENT}
	${WORKER_REFERENCE_OVERVIEW_FRAGMENT}
	${WORKER_EDUCATION_OVERVIEW_FRAGMENT}
	${WORKER_ADDRESS_FRAGMENT}
`

export const RECRUITER_WORKER_ONBOARDING_PART_FRAGMENT = gql`
	fragment workerOnboardingPartFragment on WorkerOnboardingPart {
		id
		integrationConfigurations
		integrationObject
		state
		integration {
			...workerOnboardingPartIntegrationFragment
		}
	}

	${RECRUITER_WORKER_ONBOARDING_PART_INTEGRATION_FRAGMENT}
`

export const WORKER_ASSIGNMENT_FRAGMENT = gql`
	fragment workerAssignmentFragment on WorkerAssignment {
		createdAt
		endDate
		facilitySigned
		id
		isCurrentAssignment
		isSpecialWorkWeek
		job {
			...jobFragment
		}
		worker {
			...workerFragment
		}
		shiftTypes
		startDate
		status
		translatedStatus
		updatedAt
	}
	${JOB_FRAGMENT}
	${WORKER_FRAGMENT}
`
